import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestService } from './rest.service';

@Injectable()

export class MasterService {

    constructor(private http: HttpClient, private restService: RestService) {
    }

    /**
     * getting customer data
     * @returns
     */
    // getCustomerData() {
    //     return this.restService.getData('customer');
    // }
    getEmployeeData() {
        return this.restService.getData('user?subTypeId=1')
    }
    getCustomerData() {
        return this.restService.getData('user?subTypeId=3')
    }

    /**
     * getting agent details
     * @returns
     */
    getAgentData() {
        return this.restService.getData('user?subTypeId=2')
    }

    /**
     * getting bank name with respective bank details
     * @returns
     */
    getBankData() {
        return this.restService.getData('bank-master')
    }
    getProjectBankList(accountId: any) {
        return this.restService.getData('bank-master/bankaccounts?accountId=' + accountId)
    }

    getCountryData() {
        return this.restService.getData('Location/country')
    }

}