import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ModalService {
  private isOpen = new BehaviorSubject<boolean>(false);

  constructor() { }

  //method used to opend the modal
  openModal() {
    this.isOpen.next(true);
  }

  //method is used to close the modal
  closeModal() {
    this.isOpen.next(false);
  }

  get isOpen$() {
    return this.isOpen.asObservable();
  }
}
