import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { CommonService } from './common.service';
import { DocumentFileRequest } from '../../common-components/document-file/document-file-request';
import { environment } from '../../../environments/environment';

@Injectable()
export class DocumentsService {
  saveDocument(documentFile: any) {
    throw new Error('Method not implemented.');
  }
  constructor(private restService: RestService, private commonService: CommonService) {

  }

  /**
   * This will sets the config for displaying document file upload component
   * @param userId User Id for which document file has to be uploaded
   * @param documentSubTypeId Document Sub Type Id for which doucment file will be uploaded
   * @param picFor Title of document file upload component header
   * @param showDocumentNumber if document number text box has to be displayed or not
   * @returns returns object document file data object
   */
  setDocumentFileDataConfig(userId: number, documentSubTypeId: string, picFor: string, showDocumentNumber: boolean) {
    return {
      "userId": userId,
      "documentSubTypeId": documentSubTypeId,
      "picFor": picFor,
      "showDocumentNumber": showDocumentNumber
    }
  }

  /**
   * Post Document Data
   * @returns Post API
   */
  postDocumentTemplateFile(data: any) {
    return this.restService.postData('document-processor/booking', data)
  }

  /**
   * Gets List of all documents of a user based on userId or Document sub type
   * @param userId Id of a user whose document's list has to be fetched (optional)
   * @param documentSubTypeId Id of a document sub type whose document's list has to be fetched (optional)
   * @returns returns list of documents
   */
  getFilterDocument(userId?: number, documentSubTypeId?: number, isActive?: boolean) {
    let localUrl: string = this.commonService.setDataUrl('document-processor', environment.dummyDbPath + 'documentslist.json');
    localUrl = userId ? localUrl + '?userId=' + userId : localUrl
    localUrl = documentSubTypeId ? (userId ? localUrl + '&documentSubTypeId=' + documentSubTypeId : localUrl + '?documentSubTypeId=' + documentSubTypeId) : localUrl
    localUrl = isActive ? ((userId || documentSubTypeId) ? localUrl + '&isActive=' + isActive : localUrl + '?isActive=' + isActive) : localUrl

    return this.restService.getData(localUrl);
  }

  /**
   * Update Document Data
   * @returns Update API
   */
  PostDocumentFile(data: FormData) {
    return this.restService.postFormData('document-processor/store/save', data)
  }


  /**
 * Update Document Data
 * @returns Update API
 */
  UpdateDocumentFile(data: FormData) {
    return this.restService.putFormData('document-processor/store/update', data)
  }

  /**
   * Checks if a particular sub type document is available or not in a document list
   * @param documentsList List of document in which document has to be searched
   * @param documentSubTypeName Document sub type name which has to be searched
   * @returns return object of that document which was searched (if exists), otherwise null
   */
  checkIfFileExistsBySubType(documentsList: any, documentSubTypeId: number) {
    //Checks if document list has some documents and it is not blank
    let file: any
    if (documentsList?.length > 0) {
      documentsList?.forEach((currentDocument: any) => {
        if (currentDocument?.documentSubTypeId == documentSubTypeId) {
          //file found in the list return file object
          // documentFound = true;
          // return currentDocument
          file = currentDocument
        }
      });
      return file
      //file not found in the list hence returning null
      // if (!documentFound) return null;
    }
  }

  /**
   * Gets the bytes of a document file by document id
   * @param documentId Id of document whose byte has to be fetched
   */
  getDocumentBytesById(documentId: number): any {
    let url = this.commonService.setDataUrl('document-processor/store/download-byte?documentId=' + documentId,
      environment.dummyDbPath + 'documentfile.json')
    return this.restService.getData(url)
  }

  /**
   *
   * @param userId Saves the document file, uploaded by a user
   * @param documentSubTypeId Document Sub Type Id (to define what tyoe of document file is this)
   * @param documentFile Document file (file stream) to be saved
   * @param isVerified If document file is verified or not
   * @param isActive  If document file is active or not (Soft deleted)
   * @param docuemntNumber Document Number of the file (Pan/Aadhaar Card/DL/ Passport Number)
   * @param documentId Unique Id of the document file record
   */
  saveDoucmentFile(docFileData: any) {

    //set the document data to form object as document post/save APIs take data in form object form rather than JSON
    let frmRequest = new DocumentFileRequest()
    let frm = frmRequest.setDocumentFormData(docFileData)

    //Post/Update the document file based on operation mode (if document Id available then edit else Add).
    if (!docFileData?.documentId && docFileData?.documentId == undefined)
      this.PostDocumentFile(frm).subscribe((res: any) => {
        this.commonService.showSuccess("File Uploaded Successfully.")
      })
    else
      this.UpdateDocumentFile(frm).subscribe((res: any) => {
        this.commonService.showSuccess(docFileData?.picFor + " File Uploaded Successfully.")
      })
  }


}