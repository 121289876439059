import { Injectable } from '@angular/core';
import { Cell, Column, Workbook, Worksheet } from 'exceljs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { ExportData, ExportDataField, ExportDataRow } from '../../common-components/export/export.model';
import { TFontDictionary, TFontFamilyTypes } from 'pdfmake/interfaces';

export interface ABCD {
    [fontName: string]: TFontFamilyTypes;
}

@Injectable()
export class ExportService {

    constructor() { }

    /**
     *
     * @param content the binary content
     * @param type The type of the file to be generated like 'text/csv' etc
     */
    saveFile(content: any, type: string, fileName: string) {
        const blob = new Blob([content], { type: type });
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url
        a.download = fileName;
        a.click();

        setTimeout(() => {
            if (blob) {
               // alert(url);
                URL.revokeObjectURL(url);
            }
            if (a) {
                a.remove();
            }
        });
    }
    exportToExcel(data: any[][], worksheetName: string, fileName: string) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(worksheetName);

        // Flatten the data
        const flattenedData = data.map(row => {
            const flattenedRow: any = {};
            row.forEach(cell => {
                flattenedRow[cell.fieldName] = cell.value;
            });
            return flattenedRow;
        });

        // Define columns based on field names dynamically
        worksheet.columns = Object.keys(flattenedData[0]).map(key => ({
            header: key, // Header name
            key: key,    // Property key in the row object
            width: 20    // Optional column width
        }));

        // Add rows to the worksheet
        worksheet.addRows(flattenedData);

        // Generate Excel file and save it
        workbook.xlsx.writeBuffer().then((buffer) => {
            this.saveFile(buffer, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', `${fileName}.xlsx`);
        });
    }


    // exportToExcel(data: ExportData, worksheetName: string, fileName: string) {
    //     const workbook = new Workbook();
    //     const worksheet = workbook.addWorksheet(worksheetName);

    //     worksheet.columns = this.getColumnsDefinition(data, worksheet);

    //     workbook.xlsx.writeBuffer().then((data) => {
    //         this.saveFile(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', `${fileName}.xlsx`);
    //     });
    // }

    exportToPDF(data: ExportData, title: string, fileName: string) {
        const documentDefinition = this.initializePdfmakeAndGetDocument(title);
        const tableObj = this.getPDFTable(data);

        documentDefinition.content.push({
            table: tableObj
        });

        // to open and see in the browser itself.
        // comment this line if you want to download.
        pdfMake.createPdf(documentDefinition).open();

        // to download uncomment below line
        // pdfMake.createPdf(documentDefinition).download(`${fileName}.pdf`);

    }

    /**
     * Method that creates the column definition for exceljs Worksheet.
     * and also creates and add a new row to the Worksheet.
     * Returns the array of Partial<Column>.
     */
    private getColumnsDefinition(data: ExportData, worksheet: Worksheet): Partial<Column>[] {
        let result: Partial<Column>[] = [];
        for (let i = 0; i < data.length; i++) {
            const rowObj: any = {};
            for (let j = 0; j < ((data[i] as ExportDataRow)).length; j++) {
                const expDataField = ((data[i] as ExportDataRow)[j] as ExportDataField);

                const column: Column = {
                    header: expDataField.fieldName,
                    key: expDataField.fieldName.toLowerCase(),
                    outlineLevel: 0,
                    hidden: false,
                    style: {},
                    values: expDataField.value,
                    letter: '',
                    number: 0,
                    worksheet: worksheet,
                    isCustomWidth: false,
                    headers: [],
                    isDefault: false,
                    headerCount: 0,
                    equivalentTo: (other: Column): boolean => true,
                    eachCell: (callback: any): void => { },
                    collapsed: false,
                    defn: undefined
                };

                // create row data field
                rowObj[expDataField.fieldName.toLowerCase()] = expDataField.value;

                result.push(column);
            }

            // keep adding the row
            worksheet.addRow(rowObj);
        }

        return result;
    }

    private initializePdfmakeAndGetDocument(title: string): any {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        // pdfMake.fonts =  "";   //{"": {}};
        const documentDefinition = {
            content: [
                { text: title, fontStyle: 15, lineHeight: 2 }
            ]
        };

        return documentDefinition;
    }

    private getPDFTable(data: ExportData): any {
        const table: any = {
            headerRows: 1,
            widths: [ '*', 'auto', 100, '*' ],
            body: []
        };
        if (data && data.length) {
            const columns = [];
            const rowsArrOfArr = [];
            for (let i = 0; i < data.length; i++) {
                const singleRowArray = [];
                for (let j = 0; j < ((data[i] as ExportDataRow)).length; j++) {
                    const expDataField = ((data[i] as ExportDataRow)[j] as ExportDataField);

                    if (columns && columns.length < ((data[i] as ExportDataRow)).length) {
                        columns.push(expDataField.fieldName.toUpperCase());
                    }

                    singleRowArray.push(expDataField.value);
                }

                rowsArrOfArr.push(singleRowArray);
            }

            table.body.push(columns);
            table.body.push(...rowsArrOfArr);
        }

        return table;
    }
}
